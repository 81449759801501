import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import api from '../../services/api';

import image_header from '../../assets/images/image_header.jpg';


export default function Home() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [birthday, setBirthday] = useState('');
    const [name_student, setNameStudent] = useState('');
    const [presentation, setPresentation] = useState('2');
    const [presentation_day, setPresentationDay] = useState('');
    
    function getPresentation(value){
        setPresentation(value);
        if(value=="1"){
            setPresentationDay('');
        }
    }

    function reset(){
        setName('');
        setEmail('');
        setPhone('');
        setBirthday('');
        setNameStudent('');
        setPresentation('2');
        setPresentationDay('');
        setLoading(false);
        setError(false);
        setErrorMessage('');
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        try{
            if(name==="" || email==="" || phone==="" || birthday==="" || name_student==="" || presentation===""){
                setLoading(false);
                setError(true);
                setErrorMessage("Todos os campos devem ser preenchidos!");
                return;
            }

            if(presentation==="2"){
                if(presentation_day===""){
                    setLoading(false);
                    setError(true);
                    setErrorMessage("Todos os campos devem ser preenchidos!");
                    return;
                }
            }

            const respose = await api.post('/wesleyano-apresentacao', {
                name,
                email,
                phone,
                birthday,
                name_student,
                presentation,
                presentation_day
            });
            
            setSuccess(true);
            setSuccessMessage("Informações enviadas com sucesso!");
            reset();

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Erro ao enviar informações! Tente novamente mais tarde!");
        }
    }

    useEffect(() => {
        document.title = 'Instituto Wesleyano de Ensino';
    }, []);

    return (
        <>
            <Header no_menu />
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-md-5 p-0">
                        <div className="nav-block-image" style={{backgroundImage: `url(${image_header})`}}></div>
                    </div>
                    <div className="col-md-7 p-0">
                        <div className="row m-0">
                            <div className="col-md-6 p-0">
                                <Link className="nav-block block-blue" to="/quem-nos-somos"><div>QUEM NÓS<br />SOMOS</div></Link>
                                <Link className="nav-block block-red" to="/casarao-gernheim"><div>CASARÃO<br />GERNHEIM</div></Link>
                                <Link className="nav-block block-blue" to="/nossa-equipe"><div>NOSSA<br />EQUIPE</div></Link>
                                <Link className="nav-block block-red" to="/confecionalidade"><div>CONFECIONALIDADE</div></Link>
                                <Link className="nav-block block-blue" to="/projeto-social-semear"><div>PROJETO SOCIAL<br />SEMEAR</div></Link>
                            </div>
                            <div className="col-md-6 p-0">
                                <Link className="nav-block block-orange" to="/proposta-pedagogica"><div>PROPOSTA<br />PEDAGÓGICA</div></Link>
                                <Link className="nav-block block-green" to="/educacao-infantil"><div>EDUCAÇÃO<br />INFANTIL</div></Link>
                                <Link className="nav-block block-orange" to="/educacao-fundamental"><div>ENSINO<br />FUNDAMENTAL I</div></Link>
                                <Link className="nav-block block-green" to="/periodo-integral"><div>PERÍODO<br />INTEGRAL</div></Link>
                                <Link className="nav-block block-orange" to="/contato"><div>ENTRE EM<br />CONTATO</div></Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div> 
            <Footer />
        </>
    );
}